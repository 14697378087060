import gql from "graphql-tag";

export const GET_MENUS = gql`
  query GetMenus {
    primaryMenu: menu(id: "HL Primary", idType: NAME) {
      menuItems(first: 100) {
        nodes {
          label
          uri
          id
          databaseId
          title
          cssClasses
          description
          target
          parentId
          order
          connectedNode {
            node {
              ... on Neighborhood {
                contentTypeName
                id
                basePrice
                types
                neighborhood {
                  city
                  status
                }
              }
            }
          }
        }
      }
    }
    footerCollectionsMenu: menuItems(
      where: { location: HL_FOOTER_COLLECTIONS }
    ) {
      nodes {
        id
        label
        description
        path
      }
    }
    footerNeighborhoodsMenu: menuItems(
      first: 20,
      where: { location: HL_FOOTER_NEIGHBORHOODS }
    ) {
      nodes {
        cssClasses
        description
        label
        target
        title
        uri
        url
        connectedNode {
          node {
            ... on Neighborhood {
              id
              uri
              basePrice
              title(format: RENDERED)
              neighborhood {
                city
                status
              }
              types
            }
          }
        }
      }
    }
  }
`;
