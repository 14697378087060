<template>
  <div
    id="breadcrumb-nav" class="py-4 text-sm bg-white text-fr-secondary breadcrumb-nav theme-text-base font-figtree"
  >
    <div
      :style="justifyContentStyle"
      class="flex items-center px-6 mx-auto xl:px-0 max-w-screen-xl flex-wrap"
    >
      <ul class="flex flex-wrap" v-if="hasBreadcrumbs">
        <li class="ml-3">
          <NuxtLink to="/">
            <HomeIcon class="w-5 h-5 text-[#9ca3af]" alt="Home" />
          </NuxtLink>
        </li>
        <li
          v-for="(crumb, index) in breadcrumbs.breadcrumbsList"
          :key="`crumb-${index}`"
          class="before:content-['/'] before:text-[#d1d5db]"
        >
          <a
            v-if="crumb.refreshPage"
            :href="getLink(crumb)"
            class="uppercase whitespace-nowrap"
          >
            {{ crumb.title }}
          </a>
          <NuxtLink
            v-else
            :to="getLink(crumb)"
            class="whitespace-nowrap uppercase sm:text-[13px] text-[12px] font-medium tracking-wider"
          >
            {{ crumb.title }}
          </NuxtLink>
        </li>
      </ul>
      <NuxtLink
        v-if="true"
        href="tel:254-217-8717"
        class="inline-flex items-center justify-center block text-lg font-bold theme-text-base whitespace-nowrap hover:underline"
      >
        <img
          src="/img/phone-icon-gray.png"
          alt="Phone"
          class="ml-3 sm:ml-0 mr-2 rotate-6"
          width="17"
          height="17"
        />
        254-217-8717
      </NuxtLink>
    </div>
  </div>
</template>

<style scoped>
li {
  &::before {
    @apply mx-3;
  }
}

.break-all {
  overflow-wrap: anywhere;
}
</style>

<script setup>
import { HomeIcon } from "@heroicons/vue/solid/index.js";
const breadcrumbs = useBreadcrumbs();
const route = useRoute();
watch(
  () => route.query,
  () => refreshNuxtData("route"),
);

const hasBreadcrumbs = computed(() => breadcrumbs.breadcrumbsList.length > 0);

const justifyContentStyle = computed(() => ({
  justifyContent: hasBreadcrumbs.value ? "space-between" : "flex-end",
}));

const getLink = (crumb) => {
  if (typeof crumb.link === "string") {
    return crumb.link;
  } else if (Array.isArray(crumb.link)) {
    return crumb.link.filter(Boolean).join("/");
  }
  return "";
};
</script>
